import React, { useState, useRef, useEffect } from 'react'
import { graphql } from 'gatsby'
import BEMHelper from 'react-bem-helper'
import ArtistsList from '../components/ArtistsList'
import Masonry from '../components/Masonry'
import useWindowSize from '../hooks/useWindowSize'

import './artists.scss'

const bem = new BEMHelper('artists')

const ArtistsPage = ({ data, location }) => {
  const width = useWindowSize()[0]
  const isMobile = width < 1024
  const artists = data.artists.nodes

  const imagesRef = useRef()

  const [selectedArtist, setSelectedArtist] = useState(artists[0])

  useEffect(() => {
    if (isMobile) {
      return
    }

    const storedArtistId = sessionStorage.getItem('artistId')
    if (storedArtistId) {
      const previousSelectedArtist = artists.find(
        (artist) => artist.id === storedArtistId
      )
      setSelectedArtist(previousSelectedArtist)
      sessionStorage.removeItem('artistId')
    }
  }, [artists, isMobile])

  const onArtistClick = (id) => {
    const newSelectedArtist = artists.find((artist) => artist.id === id)
    setSelectedArtist(newSelectedArtist)
  }

  return (
    <div {...bem()}>
      <div {...bem('list')}>
        <ArtistsList
          artists={artists}
          selectedArtistId={selectedArtist.id}
          onArtistClick={onArtistClick}
        />
      </div>
      {!isMobile && (
        <div {...bem('images')} ref={imagesRef}>
          <Masonry artist={selectedArtist} />
        </div>
      )}
    </div>
  )
}

export default ArtistsPage

export const query = graphql`
  query ArtistQuery {
    artists: allSanityArtist {
      nodes {
        name
        id
        slug {
          current
        }
        images {
          asset {
            id
            gatsbyImageData(layout: FULL_WIDTH)
          }
          description
        }
      }
    }
  }
`
