import React from 'react'
import { Link } from 'gatsby'
import BEMHelper from 'react-bem-helper'
import useWindowSize from '../../hooks/useWindowSize'

import './ArtistsList.scss'

const bem = new BEMHelper('artists-list')

const ArtistsList = ({ artists, selectedArtistId, onArtistClick }) => {
  const width = useWindowSize()[0]
  const isMobile = width < 1024

  const renderListItem = (artist) => {
    const { id, name, slug } = artist

    if (isMobile) {
      return <Link to={`/artists/${slug.current}`}>{name}</Link>
    }

    return (
      <button
        {...bem('artist-btn', id === selectedArtistId ? 'first' : '')}
        onClick={() => onArtistClick(id)}
      >
        {name}
      </button>
    )
  }

  return (
    <ul {...bem()}>
      {artists.map((artist) => (
        <li key={artist.id} {...bem('artist')}>
          {renderListItem(artist)}
        </li>
      ))}
    </ul>
  )
}

export default ArtistsList
